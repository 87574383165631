$font-h: "Lato", "Helvetica Neue", Helvetica, arial, sans-serif;
$font-b: "Lato", "Helvetica Neue", Helvetica, arial, sans-serif;

$c-green:		#5E6642;
$c-blue:		#1C2842;
$c-brown:		#4F321D;
$c-gray-l:		#F1F2F2;
$c-gray-m:		#9D9D9D;
$c-gray-md:		#D9D9D9;
$c-gray:		#3E3E3E;
 
$c-func-success: 	$c-green;
$c-func-warning: 	#ff2f00;
$c-func-error: 		#ff2f00;

$site-wrapped: 1200px;
$site-wrapped-narrow: 750px;
$site-wrapped-wide: 1340px;

$placeholder-color: $c-blue;
$text-color: $c-blue;
$heading-color: $c-blue;
$heading-alt-color: $c-blue; 
$blockquote-color: $c-blue;
.gform_wrapper {
	.gform_validation_container {
		display: none !important;
	}
}

.m-form-component {
	padding: 5rem;

	@include media('<tablet-sm') {
		padding: 5rem 3rem;
	}

	.m-heading {
		padding-bottom: 3.5rem;
	}
}

.m-form {
	@include form-styles; 

	&--inline {
		form {
			display: flex;
			justify-content: space-between;
			align-items: stretch;
			flex-wrap: wrap;
		}

		input:not([type=submit]):not([type=radio]):not([type=checkbox]),
		textarea,
		select { 
			padding: 1.5rem 2rem 1.9rem;

			@include media('>=tablet-md') {
				padding: 1.1rem 0 1.4rem;
			}
		}

		.gform_wrapper ul.gform_fields {
			margin: .4rem 0 0;

			li.gfield {
				position: relative;

				@include media('<tablet-md') {
					margin-bottom: 2rem;
				}

				@include media('>=tablet-sm') {
					width: calc(50% - 1rem);
					float: left;
		    		clear: none;
		    		position: relative;
		    	}

		    	@include media('>=tablet-md') {
					margin: 0;
		    	}

	    		&:first-child {
	    			@include media('>=tablet-md') {
		    			margin-right: 1rem;
		    			border-right: .1rem solid $c-gray-m;
		    		}
	    		}

	    		&.gform_hidden {
	    			margin: 0;
	    		}
	    	}
		}

		.gfield_description.validation_message {
			font-size: 1.2rem;
			margin-bottom: 0;

			@include media('>=tablet-md') {
				margin: 0;
				position: absolute;
				top: calc(100% + 2rem);
				left: 0;
			}
		}

		.validation_error {
			width: 100%;
		}

		.gform_body {
			width: 100%;

			@include media('>=tablet-md') {
				width: 70% !important;
				border-radius: 2.5rem 0 0 2.5rem;
				border: .4rem solid #fff;
				padding: 0 0 0 2rem;
			}

			ul {
				height: 100%;
				display: flex;
				align-items: center;
			}
		}

		.gform_footer {
			width: 100%;

			@include media('>=tablet-md') {
				width: 30% !important;
			}

			button {
				width: 100%;
				border-radius: 2.5rem;
				background: $c-blue;
				margin: 0;
				font-size: 1.4rem;
				text-align: center;

				@include media('>=tablet-md') {
					border-radius: 0 2.5rem 2.5rem 0;
				}

				span {
					width: 100%;
					display: block;
				}
			}
		}
	}

	&--no-labels {
		label {
			display: none !important;
		}
	}

	&--signup {
		.gform_body {
			@include media('>=tablet-md') {
				background: #fff;
			}
		}
	}

	&--light {
		label {
			color: #fff;
		}
		// input:not([type=submit]):not([type=radio]):not([type=checkbox]),
		// textarea,
		// select {
		// 	border-bottom-color: $c-green;
		// 	color: #fff;
		// 	padding-left: 1rem;
		// 	padding-right: 1rem;

		// 	&::-webkit-input-placeholder { /* WebKit browsers */
		// 		color: #fff;
		// 	}
		// 	&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		// 		color: #fff;
		// 	}
		// 	&::-moz-placeholder { /* Mozilla Firefox 19+ */
		// 		color: #fff;
		// 	}
		// 	&:-ms-input-placeholder { /* Internet Explorer 10+ */
		// 		color: #fff;
		// 	}

		// 	&:focus {
		// 		background-color: $c-blue;
		// 	}
		// }

		// .gform_footer {
		// 	button {
		// 		//background: linear-gradient(90deg, $c-green, $c-green) !important;
		// 	}
		// }

		// .gform_confirmation_wrapper {
		// 	//color: #fff;
		// }
	}
}
.m-shortcode {
	width: 100%;
}
.m-loginregister {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 80%;
	margin: 4rem auto;

	@include media('<tablet-nav') {
		width: 100%;
		margin: 0 auto;
	}
}
.m-login,
.m-register {
	width: 45%;

	@include media('<tablet-nav') {
		width: 80%;
		margin: 4rem auto;
	}
}

/**
 * Header
 */

body {
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center top;
}

$hamwidth: 2.4rem;
$hamheight: .2rem;
$hamcolor: $c-green;
$hamoffset: 3;
.m-burger {
	font-size: 0;
	display: block;
	width: 3.7rem;
    height: 3.7rem;
    padding: 1rem .2rem .5rem .6rem;
	transition: transform 500ms ease-in-out, color 250ms ease-in-out; 
	border-radius: 5rem;
	margin: 0 auto;

	@include media('>=tablet-nav') {
		display: none !important;
	}

	&--active:hover {
		&__lines {
			background: transparent;
		}
	}

	&--active &__lines {
		background: transparent;

		&::before, &::after {
			top: 0;
			left: 0;
			width: $hamwidth;
			transform-origin: 50% 50%;
			background: $hamcolor;
		}

		&::before {
			transform: rotate3d(0, 0, 1, 45deg);
		}

		&::after {
			transform: rotate3d(0, 0, 1, -45deg);
		}

	}

	&__lines {
		position: relative;
		top: 0;
		display: inline-block;
		width: $hamwidth;
		height: $hamheight;
		transition: all 250ms ease-in-out;
		background: $hamcolor;
		top: $hamheight * $hamoffset;

		&::before, &::after {
			position: absolute;
			right: 0;
			display: block;
			width: $hamwidth;
			height: $hamheight;
			content: '';
			transition: all 250ms ease-in-out;
			transform-origin: 0 center;
			background: $hamcolor;
		}

		&::before {
			top: $hamheight * $hamoffset;
			width: $hamwidth;
		}

		&::after {
			top: -$hamheight * $hamoffset;
		}
	}
}

.m-responsive-navs {
	width: 100%;
	padding: 1rem 0 4rem;
	z-index: 999;
	display: none;
	background: $c-blue;
	
	@include media('>=tablet-nav') {
		display: none !important;
	}

	&__inner {
		display: flex;
		min-height: 76vh;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	li {
		text-align: center;

		&.mobile-only {
			@include media('>=tablet-sm') {
				display: none;
			}
		}

		&.mobile-sm-only {
			@include media('>=phone-md') {
				display: none;
			}
		}

		&.m-highlighted-link {
			a {
				background: $c-blue;
			}
		}

		a {
			font-size: 2.1rem;
			display: inline-block;
    		padding: 3vh 1rem;
    		color: #fff;
    		position: relative;
    		width: 100%;
    		cursor: pointer;

    		&:hover {
    			color: #fff;
    			text-decoration: underline;
    		}

    		@include media('<phone-lg') {
				font-size: 1.8rem;
    		}

    		@include media('<phone-sm') {
				font-size: 1.6rem;
    		}
		}

		&.menu-item-has-children {
			> a {
				position: relative;

				&::after {
					content: '';
					display: inline-block;
					height: 1rem;
    				width: 1rem;
					background: url(../images/icn_mobile_dropdown.svg) center/contain no-repeat;
					opacity: .5;
				    right: 8rem;
				    margin: 0 -.9rem 0 .5rem;
				}
			}

			&.active {
				> a {
					&::after {
						transform: rotate(-180deg);
					}
				}
			}
		}

		.sub-menu {
			display: none;
			background: darken($c-blue, 5%);

			li {
				&:not(:last-child) {
					&::after {
						background: $c-blue;
					}
				}

				a {
					font-size: 1.4rem;
				}
			}
		}
	}
}

.menu-main-menu-container {
	position: relative;
}

.m-amenities {
	padding: 2rem 0;

	&__item {
		position: relative;
		min-height: 20rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__title {
		font-size: 2.4rem;
		text-align: center;
		z-index: 2;
		position: relative;
	}

	&.l-grid {
		grid-column-gap: 1.5rem;
    	grid-row-gap: 1.5rem;
	}
}

.m-amenities-list {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include media('<laptop') {
		flex-direction: column;
	}

	ul {
		display: flex;
		align-content: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: 100%;
		margin-bottom: 2rem;

		@include media('>=laptop') {
			width: calc(100% - 40rem);
			margin-bottom: 0;
		}

		@include media('<phone-lg') {
			flex-direction: column;
		}

		li {
			position: relative;
			white-space: nowrap;
			font-weight: 900;
			display: block;

			@include media('<phone-lg') {
				width: 100%;
			}

			@include media('<tablet-sm') {
				margin-bottom: 2rem;
			}

			@include media('>=tablet-sm') {
				text-align: center;
			}

			&::before {
				font-size: 1.7rem;
				font-weight: 400;
				content: '+';
				display: inline-block;
				width: 2rem;

				@include media('<phone-lg') {
					float: left;
				}

				@include media('>=phone-lg') {
					position: absolute;
					right: -3.5rem;
					top: calc(50% - 1rem);
				}

				@include media('>=tablet-lg') {
					right: -6rem;
				}
			}

			&:not(:last-of-type) {
				@include media('>=phone-lg') {
					margin-right: 5rem;
				}

				@include media('>=tablet-lg') {
					margin-right: 10rem;
				}
			}

			&:last-of-type {
				&::before {
					@include media('>=phone-lg') {
						display: none;
					}
				}
			}

			br {
				@include media('<phone-lg') {
					display: none;
				}
			}

			div {
				display: block;
				line-height: 1.2;

				@include media('>=phone-lg') {
					display: inline-block;
				}
			}
		}
	}

	.m-button {
		@include media('<laptop') {
			margin: 0 auto 0 0;
		}
		@include media('>=laptop') {
			align-self: flex-end;
			margin-right: 0;
		}
	}
}

.m-heading {
	display: block;
	width: 100%;
	
	&--center {
		text-align: center;

		h2 {
			&::after {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

// .m-heading--xs + .m-heading--xl,
// .m-heading--xs + .m-heading--lg {
// 	margin-bottom: 4rem;
// }

// .m-heading--xl + .m-heading--md,
// .m-heading--lg + .m-heading--md {
// 	margin-top: -4rem;
// 	margin-bottom: 4rem;
// }

// .m-heading--xl + .m-heading--xxs,
// .m-heading--lg + .m-heading--xxs {
// 	margin-bottom: 2rem;
// }

hr-fancy {
	display: block;
	width: 100%;
}

.m-hr__line {
	display: block;
	height: 2px;
	background-color: $c-green;

	&--smline {
		width: 6rem;
	}
	&--mdline {
		width: 45rem;
	}
	&--lgline {
		width: 100%;
	}
}

.m-contact-block {
	.m-heading {
		margin-bottom: 2rem;
	}

	&__content {
		padding: 5rem 0 5rem;

		@include media('>=tablet-sm') {
			padding: 8rem 12% 8rem 0;
		}
	}

	.m-form {
		margin-top: 4rem;
	}
}

.m-contentblock {
	width: 100%;
	margin-bottom: 1rem;

	* {
		&:last-child {
			margin-bottom: 0;
		}
	}

	&--med {
		.m-contentblock__inner {
			width: 80rem;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&--narrow {
		.m-contentblock__inner {
			width: 63rem;
			margin-left: auto;
			margin-right: auto;
		}
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: 900;
	}
	img {
		padding-bottom: 3rem;
	}
	figure {
		margin-bottom: 3rem;

		img {
			padding-bottom: 0;
		}

		&.aligncenter {
			@include media('>=tablet-lg') {
				max-width: 120%;
				margin-left: -10%;
			}

			figcaption {
				text-align: center;
			}
		}

		figcaption {
			padding: 1rem 0 1rem;
			font-size: 1.6rem;
			font-weight: 300;
			font-style: italic;
			border-bottom: .1rem solid $c-green;
		}
	}

	p {
		//font-size: 1.8rem;
		&:not(:last-child) {
			margin-bottom: 3rem;
		}

		img {
			padding-bottom: 0;
		}
	}

	ul {
		padding-left: 3rem;
		position: relative;
		margin: 3rem 0 4rem 2rem;

		li {
			margin: 0;
			position: relative;
			color: $c-blue;
			font-weight: 700;

			&::before {
			    width: 2rem;
			    height: .1rem;
			    background: $c-blue;
			    top: 1.2rem;
			    left: -3rem;
			    position: absolute;
			    content: '';
			    display: block;
			}

			a {
				text-decoration: underline;
				color: $c-green;
			}
		}
	}

	li {
		line-height: 1.5;

		&:not(:last-child) {
			padding-bottom: 1.5rem;
		}
	}

	ol {
		counter-reset: ol-list-counter;
		list-style: none;
		margin: 3rem 0 4rem;
		
		li {
			counter-increment: ol-list-counter;
			position: relative;
			color: $c-blue;
			padding: 1rem 0rem 0rem 5rem;
		    border-radius: 2.7rem;

			&::before {
				content: counter(ol-list-counter);
				color: #fff;
				font-size: 1.4rem;
				font-weight: 700;
				font-family: $font-h;
				position: absolute;
				left: .7rem;
				width: 3rem;
				height: 3rem;
				top: .5rem;
				background: $c-blue;
				border-radius: 50%;
				text-align: center;
				line-height: 2;
				transition: all .3s ease-in-out;
			}
		}
	}

	hr {
		border: 0;
    	border-top: 1px solid #E6E6E6;
    	margin: 2.5rem 0;
	}

	// &:not(:last-child) {
	// 	//margin-bottom: 2.5rem;
	// }
}

.m-content-form {
	.l-col {
		margin-bottom: 0 !important;

		@include media('<tablet-md') {
			//width: 100% !important;
		}
	}
	&__hero {
		position: relative;
		display: flex;
		align-items: flex-end;
		width: 100%;

		@include media('>=tablet-lg') {
			padding-right: 5rem;
		}

		&__content {
			display: flex;
			align-items: center;
			width: 100%;

			@include media('>=tablet-sm', '<tablet-md') {
				flex-direction: column;
				align-items: flex-start;
			}

			@include media('<phone-lg') {
				flex-wrap: wrap;
			}
		}

		.m-price {
			margin: 0 4rem;
			width: 24rem;

			@include media('>=tablet-sm', '<tablet-md') {
				margin-top: 3rem;
				width: 12rem;
			}

			@include media('<phone-lg') {
				width: 13rem;
				margin: 4rem 2rem -4rem auto;
			}
		}
	}
	&__content {
		padding: 4rem 0 5rem;
		background: #fff;
		position: relative;
		margin: 0 !important;

		@include media('>=tablet-sm') {
			padding: 5rem 5rem 5rem 0;
		}
	}
	&__form {
		padding: 5rem 0 5rem;

		@include media('>=tablet-sm', '<tablet-lg') {
			padding-left: 2rem;
		}

		@include media('>=tablet-lg') {
			padding: 2rem 0 5rem 10%;
		}

		.m-heading {
			padding-bottom: 3rem;
		}
	}
}

.m-price-grid {
	margin: 1rem 0;
	h4 {
		font-weight: 900;
		margin-bottom: 2rem;
	}

	.m-price {
		position: relative;

		&::before {
		    width: .1rem;
		    height: 100%;
		    top: 0;
		    left: -3rem;
		    position: absolute;
		    content: '';
		    display: block;
		    background: $c-green;
		}
	}

	.l-grid {
		grid-column-gap: 7rem;
		grid-row-gap: 3rem;

		@include media('<phone-lg') {
			grid-column-gap: 5rem;
		}
	}

	.l-grid--3 {
		@include media('>=phone-sm') {
			grid-template-columns: 1fr 1fr 1fr;
		}

		.m-price {
			&:nth-child(3n + 1) {
				&::before {
					display: none;
				}	
			}
		}
	}

	.l-grid--1 {
		.m-price {
			&::before {
				display: none;
			}
		}
	}
}

.m-price {
	color: $c-green;
	position: relative;

	span {
		display: block;
	}

	&__pre {
		font-size: 2.4rem;
		font-weight: 900;
		margin-bottom: .5rem;

		@include media('<laptop') {
			font-size: 1.8rem;
		}

		@include media('<phone-lg') {
			font-size: 1.4rem;
		}
	}
	&__title {
		font-size: 5rem;
		font-weight: 900;
		margin-bottom: .5rem;

		@include media('<laptop') {
			font-size: 3.5rem;
		}

		@include media('<phone-lg') {
			font-size: 3rem;
		}
	}
	&__sub {
		font-weight: 400;
		font-size: 1.4rem;
		
		@include media('<laptop') {
			font-size: 1.2rem;
		}

		@include media('<phone-lg') {
			font-size: 1rem;
		}
	}

	&--circle {
		color: #fff;

		span {
			text-align: center;
			position: relative;
			z-index: 1;
		}

		.m-price__sub {
			text-align: center;
		}

		&::before,
		&::after {
		    width: calc(100% + 8rem);
		    padding-top: calc(100% + 8rem);
		    top: 50%;
		    left: -4rem;
		    position: absolute;
		    content: '';
		    display: block;
		    border-radius: 100rem;
		    background: $c-green;
		    z-index: 0;
		    transform: translateY(-50%);

		    @include media('<laptop') {
				width: calc(100% + 4rem);
		    	padding-top: calc(100% + 4rem);
		    	left: -2rem;
		    }
		}

		&::after {
			background: none;
			border: .1rem solid $c-green;
			transform: scale(1.05) translateY(calc(-50% + .5rem));
			opacity: .2;
		}
	}
}

.m-image {
	display: block;
	position: relative;

	picture {
		display: block;
		width: 100%;
		height: auto;
		position: relative;

		img {
			//border-radius: 3px;
			position: relative;
			z-index: 2;
		}

		&::before {
		    width: calc(100% - 2rem);
		    height: calc(100% + 3rem);
		    top: -1.5rem;
		    left: 1rem;
		    position: absolute;
		    content: '';
		    display: block;
		    z-index: 0;
		    background: $c-green;

		    @include media('<tablet-sm') {
				height: calc(100% + 1.5rem);
				top: -.75rem;
		    }
		}
	}

	&--circle {
    	border-radius: 50%;
    	padding: 6rem;

		@include media('<tablet-sm') {
    		max-width: 56rem;
    		margin: 0 auto 2rem;
    	}

		picture {
			position: relative;
			z-index: 5;

			img {
				float: left;
				border-radius: 50%;
			}
		}
	}
}

.m-circle-callouts {
	display: flex;

	@include media('<laptop') {
		flex-wrap: wrap;
	}
}

.m-circle-callout {
	display: flex;
	position: relative;
	padding: 5rem 5% 5rem 7rem;
	font-size: 2.1rem;
	font-weight: 700;

	@include media('<tablet-sm') {
		padding: 2.5rem 2rem 2.5rem 4rem;
	}

	@include media('>=tablet-sm', '<laptop') {
		width: 50%;
	}

	&__thumb {
		margin-right: -2rem;
		border-radius: 10rem;
		overflow: hidden;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: -1;
		left: 0;
		width: 9.5rem;
		height: 9.5rem;

		@include media('<tablet-sm') {
			width: 5rem;
			height: 5rem;
		}

		&::before {
		    width: 100%;
		    height: 100%;
		    top: 0;
		    left: 0;
		    position: absolute;
		    content: '';
		    display: block;
		    background: linear-gradient(-45deg, rgba($c-brown, .7), rgba($c-brown, 0));
		}
	}

	a {
		color: #fff !important;
		font-size: 2.1rem;
		font-weight: 700;
	}
}

.m-gallery {
	@include media('<tablet-sm') {
		margin: 0 2rem 5rem;
    	width: calc(100% - 4rem);
	}

	img {
		border: .4rem solid #fff;
		cursor: pointer;
	}
}

.m-video {
	display: block;
	width: 100%;
	cursor: pointer;
	margin-bottom: 1rem;

	&__thumb {
		position: relative;
		display: flex;
	}

	&__caption {
		padding-top: 1rem;
		color: $c-green;
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	&__title {
		font-size: 1.8rem;

		b {
			font-weight: 900;
		}
	}

	&__length {
		font-size: 1.2rem;
		font-weight: 900;
	}

	&__overlay {
		position: absolute;
		color: #fff;
		font-size: .8rem;
		font-weight: 700;
		line-height: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: rgba(#000, .3);

		svg {
			width: 7rem;
			margin-bottom: 1rem;

			path {
				fill: #fff;
			}
		}
	}

	img {
		float: left;
	}
}

.m-post-meta {
	display: flex;
	align-items: center;

	.m-post-date {
		color: $c-green;
		font-weight: 300;
		font-size: 1.3rem;
	}

	.m-share {
		align-self: flex-end;
		margin: 0 0 0 auto;
	}
}

.m-share {
	color: #fff;
	display: flex;
	align-items: center;

	&__title {
		color: #fff;
		font-style: italic;
		font-size: 1.3rem;
		font-weight: 300;
		margin-right: 1.5rem;
	}

	ul {
		display: flex;
		li {
			&:not(:last-of-type) {
				margin-right: 1rem;
			}
		}
	}

	a {
		&:hover {
			svg {
				transform: scale(1.2);

				path {
					fill: $c-green;
				}
			}
		}
	}

	svg {
		width: 3rem;
		height: 3rem;
		transition: all .3s ease-in-out;

		path {
			fill: #fff;
			transition: all .3s ease-in-out;
		}
	}
}

// .m-post-list {
// 	&__item {
// 		a {
// 			display: flex;
// 			flex-direction: column;

// 			&:hover {
// 				.m-post-list__button {
// 					opacity: 1;
// 				}
// 			}
// 		}
// 	}
// 	&__title {
// 		font-size: 1.2rem;
// 		margin: 1.5rem 0 3.5rem;
// 	}

// 	&__button {
// 		margin: 0 0 2rem;
// 		opacity: .2;
// 		transition: all .3s ease-in-out;
// 		align-self: flex-end;
// 		font-size: 1.2rem;
// 		font-weight: 700;
// 	}

// 	article {
// 		&:not(:last-of-type) {
// 			margin-bottom: 2rem;

// 			&::after {
// 			    width: 100%;
// 				height: .2rem;
// 				background: $c-green;
// 				content: '';
// 				display: block;
// 			}
// 		}
// 	}

// 	h5 {
// 		font-weight: 700;
// 		font-size: 2.1rem;
// 		margin: 0 0 .5rem;
// 	}

// 	p {
// 		font-size: 1.3rem;
// 		line-height: 1.7;
// 		margin: 0 0 .5rem;
// 	}

// 	.m-button {
// 		display: inline-block;
// 	    width: auto;
// 	    float: right;
// 	}
// }

.m-posts {
	@include media('<tablet-sm') {
		margin-bottom: 4rem;
	}
	h3 {
		color: #fff;
		a {
			color: #fff;
		}
	}
}

.m-postgrid {
	grid-column-gap: 4rem;
	grid-row-gap: 3rem;
}

mini-post {
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	position: relative;
	border-radius: .6rem;
	display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-end;
    justify-content: flex-end;

	@include media('<tablet-sm') {
    	min-height: 66vw;
    }

	@include media('>=phone-lg', '<tablet-lg') {
		display: flex;

		&:nth-of-type(even) {
			flex-direction: row-reverse;
		}
	}

	&:hover {
		.m-minipost__content {
			.m-button {
				opacity: 1;
			}
		}
	}

	&::before {
		width: calc(100% - 4rem);
		height: .2rem;
		bottom: -1rem;
		border-radius: 4rem;
		right: 1rem;
		border: .2rem solid $c-green;
		z-index: 1;
	}
}

.m-minipost {
	padding-top: 25%;
	position: relative;
	border-radius: .6rem;
	display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-end;
    justify-content: flex-end;
    line-height: 1.5;

	@include media('>=phone-lg', '<tablet-lg') {
		display: flex;

		&:nth-of-type(even) {
			flex-direction: row-reverse;
		}
	}

	&:hover {
		.m-minipost__content {
			.m-button {
				opacity: 1;
			}
		}
	}

	&::before {
		width: calc(100% - 4rem);
		height: .2rem;
		bottom: -1rem;
		border-radius: 4rem;
		right: 1rem;
		border: .2rem solid $c-green;
		z-index: 1;
	}

	&__header {
		display: block;
		position: relative;
		position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;

		@include media('>=phone-lg', '<tablet-lg') {
			width: 50%;
			min-height: 32vw;
		}

		&::before,
		&::after {
			position: absolute;
			content: '';
			display: block;
		}

		&__overlay {
			height: 100%;
			position: relative;
		    display: flex;
		    align-items: flex-end;
		    flex-direction: column;
		    align-content: flex-end;
		    justify-content: flex-end;
		    text-align: center;
		    z-index: 5;
		    background: linear-gradient(0deg, rgba($c-blue, .4), rgba($c-blue, 0)) !important;
		}

		a {
			display: block;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			height: 100%;

			defer-image {
				position: absolute;
			    top: 0;
			    height: 100%;
			    width: 100%;
			    overflow: hidden;
			}

			defer-image,
			picture {
				display: block;
			}
			picture {
				width: 100%;
				height: 100%;
				object-fit: cover;

				img {
					object-fit: cover;
					height: 100%;
    				width: 100%;
				}
			}

			img {
				object-fit: cover;
				height: 100%;
				width: 100%;
				position: relative;
				z-index: 0;
			}
		}
	}
	&__content { 
		padding: 5rem 3.5rem 5rem;
		text-align: left;
		position: relative;
		z-index: 5;
		bottom: 0;

		@include media('<tablet-sm') {
			padding: 3rem;
		}

		@include media('>=phone-lg', '<tablet-lg') {
			width: 50%;
			text-align: left;
			padding: 3rem 3rem 3rem;
			justify-content: center;
		    display: flex;
		    flex-direction: column;
		}

		.m-button-container {
			padding: 1rem 0 0;
		}
	}
	&__title {
		font-weight: 700;
		width: 100%;
		margin-bottom: 1rem;
		line-height: 1.2;
		color: #fff;

		&:hover {
			text-decoration: underline;

			a {
				color: #fff;
			}
		}

		a {
			color: #fff;
		}
	}
	&__date {
		font-size: 1.4rem;
		width: 100%;
		color: #fff;
		margin-bottom: 2rem;
		display: block;
		line-height: 1.5;
	}
	&__excerpt {
		color: #fff;
		font-size: 1.6rem;
		font-weight: 400;
		line-height: 1.5;
		margin-bottom: 1rem;
	}
}

.m-card {
	$root: &;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	position: relative;

	&__header {
		display: block;
		width: 100%;
		padding: 0 3rem;
		position: relative;

		defer-image,
		picture {
			display: block;
		}
		img {
			width: 100%;
			height: auto;
		}
	}
	&__body {
		display: block;
		width: 100%;
		margin: 3rem 0;
	}
	&__title,
	&__subtitle {
		display: block;
		width: 100%;
		text-align: center;
	}
	&__title {
		font-size: 2.4rem;
		color: $c-blue;
		margin-bottom: 1rem;

		a {
			color: $c-blue;
		}
	}
	&__subtitle {
		font-size: 1.8rem;
		font-family: $font-b;
		color: $c-func-error;
	}
	&__footer {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
	}
}

/**
 * Testimonials
 */
.m-testimonial {
	text-align: center;
	padding: 3rem 5%;
	width: 100%;

	&__content {
		font-size: 1.7rem;
		line-height: 1.8;
		padding: 0 0 3rem;
		max-width: 80rem;
    	margin: 0 auto;

    	@include media('<tablet-sm') {
			line-height: 1.6;
		}
	}
	&__thumb {
		margin: 0 auto;
		width: 9rem;
		border-radius: 5rem;

		img {
			border-radius: 5rem;
		}
	}
	&__name {
		font-size: 2.6rem;
		color: $c-blue;
		margin-bottom: 1.5rem;

		@include media('<tablet-sm') {
			font-size: 2.2rem;
		}
	}
	&__position {
		font-size: 1.6rem;
		color: $c-blue;

		@include media('<tablet-sm') {
			font-size: 1.4rem;
		}
	}
}

/**
 * Sliders
 */
.glide {
	// .glide__slide {
	// 	//align-self: center;
	// }
	.glide__arrows {
		width: 100%;
		position: relative;

		@include media('<laptop') {
			margin-top: 1rem;
		}

		@include media('>=laptop') {
			position: absolute;
			height: 0;
			top: 60%;
		}

		&.glide__arrows--white {
			.glide__arrow {
				border-color: #fff;

				&.glide__arrow--right {
					background-image: url(../images/icn_arrow_right_white.svg);
				}
				&.glide__arrow--left {
					background-image: url(../images/icn_arrow_left_white.svg);
				}
			}
		}
	}
	.glide__arrow {
		padding: 1rem 0;
		background: none;
		border-radius: 4rem;
		border: .2rem solid $c-green;
		height: 4rem;
		width: 4rem;
		text-align: center;
		position: absolute;
		bottom: -4rem;
		font-size: 0;
		outline: 0;
		cursor: pointer;
		transition: all .3s ease-in-out;
		background-repeat: no-repeat;
		background-size: 1rem;

		@include media('<laptop') {
			top: 0;
		}

		&:hover {
			border-color: $c-blue;
		}

		&.glide__arrow--right {
			right: 0;
			background-image: url(../images/icn_arrow_right_green.svg);
			background-position: 50% center;

			&:hover {
				background-position: 60% center;
			}

			@include media('>=laptop') {
				right: -6rem;
			}
		}

		&.glide__arrow--left {
			left: 0;
			background-image: url(../images/icn_arrow_left_green.svg);
			background-position: 50% center;

			&:hover {
				background-position: 40% center;
			}

			@include media('>=laptop') {
				left: -6rem;
			}
		}
	}
}

.m-testimonial-slider {
	padding: 2rem 0 7rem;

	@include media('<tablet-sm') {
		padding: 0 0 9rem;
	}

	.m-testimonial {
		padding: 0;
	}

	&.glide .glide__arrow {
		bottom: -1rem;

		@include media('<tablet-sm') {
			bottom: 0;
		}
	}
}

.m-post-slider {
	.m-minipost {
		&__content {
			min-height: 38rem;
		}
	}
}

.single {
	.post {
		.l-wrapper {
			padding-top: 0;
		}
	}
}

.m-signup {
	position: relative;
	margin: .4rem 0 0;
	padding: 6rem 0 9rem;
	z-index: 1;

	.l-wrapper {
		width: 96rem;
	}

	.l-col {
		@include media('<tablet-md') {
			width: 100% !important;
		}

		&:first-of-type {
			@include media('<tablet-md') {
				margin-bottom: 2rem;
			}
		}
	}

	&::before {
	    width: 100%;
	    height: 100%;
	    top: 0;
	    left: 0;
	    position: absolute;
	    content: '';
	    display: block;
	    background: linear-gradient(35deg, rgba($c-green, .85), $c-green 30%, $c-green 40%, rgba($c-green, .85));
	    z-index: 1;
	}

	&__heading {
		display: flex;
		flex-direction: column;

		&__pre {
			font-size: 1.4rem;
			font-weight: 400;
			opacity: .5;
			margin-bottom: 1rem;
		}
		&__title {
			font-weight: 300;
			font-size: 3.4rem;
			margin-bottom: 1rem;

			b {
				font-weight: 900;
			}
		}
		&__sub {
			font-size: 1.7rem;
			font-weight: 300;

			@include media('>=tablet-md') {
				text-align: right;
				margin-left: 0;
				align-self: flex-end;
			}

			b {
				font-weight: 900;
			}
		}
	}
}

/*
Video Embeds
*/
.m-embed-container {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    width: 100%;
    overflow: hidden;

    iframe,
	object,
	embed { 
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

/**
 * FOOTER
 */
body .m-footer {
	position: relative;
	z-index: 20;
	padding: 7rem 0 4rem;
	color: $c-blue;
	font-size: 1.2rem;
	background: $c-gray-l;
	z-index: 3;

	@include media('<tablet-lg') {
		padding: 6rem 0 3rem;
	}

	@include media('<tablet-sm') {
		padding: 4rem 0 3rem;
	}

	.l-wrapper {
		padding: 0;
		//width: 1240px;
		position: relative;
		z-index: 5;

		@include media('<tablet-sm') {
			padding: 0 2rem;
		}
	}

	.l-grid--4 {
		@include media('>=tablet-sm', '<tablet-lg') {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

	.l-col {
		@include media('<phone-lg') {
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}

	&__logomark {
		position: absolute;
		top: -3.2rem;
		left: calc(50% - 3.2rem);
		width: 6.4rem;
	}

	.menu-footer-menu-container {
		width: 100%;
		display: flex;
		justify-content: flex-end;

		@include media('>=phone-lg', '<tablet-lg') {
			justify-content: flex-start;
		}
	}

	&__widgets {
		margin-bottom: 6rem;

		aside {
			&:nth-child(1) {
				@include media('>=phone-md', '<tablet-sm') {
				    grid-column-start: 1;
				    grid-column-end: 3;
				}
				@include media('>=tablet-lg') {
				    grid-row-start: 1;
				    grid-row-end: 3;
				}
			}
		}

		.menu {
			display: flex;
			flex-direction: column;
			width: 100%;

			> li {
				margin-bottom: 1.4rem;

				&:first-child {
					margin-bottom: 2rem;

					a {
						font-weight: 900;
						font-size: 1.8rem;
					}
				}
			}

			a {
				color: $c-blue;
				font-size: 1.5rem;
				font-weight: 400;
				display: flex;
				flex-wrap: wrap;
				align-items: center;

				&:hover {
					text-decoration: underline;
				}

				.bubble {
					display: inline-block;
					margin-left: .4rem;
					font-size: .9rem;
					font-weight: 900;
					background: $c-blue;
					color: #fff;
					border-radius: 3rem;
					padding: .4rem .8rem;
				}
			}
		}
	}

	#menu-footer-menu.menu {
		display: flex;
		flex-direction: row;
		width: 100%;

		li {
			margin-bottom: 1.4rem;

			&:not(:last-child) {
				padding-right: 2rem;
				margin-right: 2rem;
				border-right: .1rem solid $c-gray-m;
			}
		}

		a {
			color: $c-gray-m;
			font-size: 1.1rem;
			font-weight: 700;
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			&:hover {
				text-decoration: underline;
			}

			.bubble {
				display: inline-block;
				margin-left: .4rem;
				font-size: .9rem;
				font-weight: 900;
				background: $c-blue;
				color: #fff;
				border-radius: 3rem;
				padding: .4rem .8rem;
			}
		}
	}

	.m-nft-social {
		.m-nft-social__list {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			font-size: 0;
			margin-top: 1rem;

			@include media('<tablet-lg') {
				width: 100%;
			}

			li {
				&:not(:last-of-type) {
					margin-right: 1rem;
				}
			}

			a {
				width: 3.5rem;
				height: 3.5rem;
				display: block;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				border-radius: 5rem;
				transition: all .3s ease-in-out;
				font-size: 0;
				background: $c-blue;

				@include media('>=tablet-sm') {
					width: 4rem;
					height: 4rem;
				}

				&:hover {
					background: $c-green;
				}

				svg {
					position: relative;
					z-index: 3;
					width: auto;
					height: 1.2rem;
					max-width: 1.4rem;

					path {
						fill: #fff;
					}
				}

				&:not(:last-child) {
					margin-right: 1.5rem;
				}
			}
		}
	}

	.m-map {
		//max-width: 45rem;

		a {
			font-size: 1.2rem;
			color: $c-green;
			font-weight: 700;
		}

		img {
			border-radius: .8rem;
			margin-bottom: 1rem;

			@include media('>=tablet-sm') {
				max-width: 20rem;
			}
		}
	}

	.m-logomark {
		&::before {
			content: '';
			background: url('../images/logomark.svg') center/contain no-repeat;
			width: 24rem;
		    height: 30rem;
		    position: absolute;
		    bottom: -17rem;
		    right: 0;
		    display: block;
		    z-index: 99;
		    opacity: .3;

			@include media('<tablet-lg') {
			    width: 36vw;
			    height: 47vw;
			    bottom: -26vw;
			}
		}
	}

	.m-copy {
		text-align: left;
		color: $c-gray-m;
		line-height: 1;

		@include media('<phone-lg') {
			width: 100%;
			padding: 0 0 3rem;
		}
	}
}

.m-comments {
	.comment-form-comment {
		display: flex;
		flex-direction: column;
	}
	input[type="submit"] {
		@include button;
	}
}

.m-pagination {
	width: 100%;
	text-align: center;
	margin-top: 5rem;

	li {
		display: inline-block;
		margin: 0 .5rem;

		span,
		a {
			display: block;
			width: 4rem;
			height: 4rem;
			padding: .3rem 0;
			border-radius: 5rem;
			border: .2rem solid transparent;
			color: $c-blue;
			font-weight: 700;
			text-align: center;
			line-height: 2;
			transition: all .3s ease-in-out;

			&.current {
				border-color: $c-blue;
				background: $c-blue;
				color: #fff;
			}

			&:hover {
				border-color: $c-blue;
			}
		}
	}
}

.m-popup {
	position: fixed;
	z-index: 999;
	top: 0; 
	bottom: 0;
	left: 0;
	display: flex;
	width: 100%;
	height: 100%;
	transition: opacity 0ms ease-in-out;
	pointer-events: none;
	opacity: 0;
	background-color: rgba($c-blue, .97);
	align-items: center;
	justify-content: center;
	height: 0;
	// overlay: hidden;

	&.active {
		pointer-events: all;
		opacity: 1;
		height: auto;
		transition: opacity 300ms ease-in-out;
	}

	&__content {
		position: relative;
		width: 90%;
		height: auto;
		max-width: 110rem;
		max-height: calc(100vh - 20rem);
		transition: all .3s ease-in-out;
		overflow-y: scroll;
		background: #fff;

		@include media('>=tablet-sm') {
			max-height: calc(100vh - 12rem);
		}

		&.hidden {
			opacity: 0;
		}

		* {
			&.hidden {
				display: none;
			}
		}
	}

	&--video {
		.m-popup__content {
			background: none;
		}
		.m-popup__wrapper {
			width: 100%;
			max-height: 200vh;
			position: static;
			background: none;

			@include media('>=tablet-lg') {
				max-width: 70vw;
			}
		}
		.m-popup__wrapper__inner {
		    position: relative; 
			padding: 0 0 56.25%;
			overflow: hidden;
			max-width: 100%;
			height: 0;
			width: auto;

		    iframe, object, embed { 
		        position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
		    }
		}
	}

	&--gallery {
		img {
			height: auto;
			max-height: 70vh;
			margin-bottom: 1rem;
		}

		.m-popup__content {
			background: none;
			text-align: center;
			overflow: visible;
		}
		.m-popup-gallery {
			margin: 0 auto;
		}

		figure {
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			width: 100%;
			flex-direction: column;
			color: #fff;
		}

		.glide .glide__arrows {
			top: calc(50% - 3rem);
		}
	}

	&__close {
		position: absolute;
	    top: 2rem;
	    right: 2rem;
	    font-size: 2rem;
	    line-height: 1.9;
	    transition: all .3s ease-in-out;
	    display: flex;
	    align-items: center;
	    padding: 0rem;
	    color: #fff;
	    font-size: 1.6rem;
	    z-index: 99999;
	    cursor: pointer;

	    @include media('>=tablet-sm') {
			padding: 1rem;
	    }

	    @include media('>=tablet-sm') {
			top: 2rem;
	   		right: 3rem;
	    }

	    span {
	    	display: inline-block;
	    	font-size: 3rem;
	    	color: #fff;
	    	font-family: $font-b;
	    }

	    &:hover {
	    	opacity: 1;
	    	//border-color: $c-brand-orange;
	    	//background: $c-brand-orange;
	    	color: #fff;
	    }
	}

	&__overlay {
		position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    cursor: pointer;
	    opacity: .97;
	}

	&__copy {
		margin-bottom: 2rem;

		@include media('<tablet-sm') {
			text-align: center;
		}		
	}

	&__nav {
		position: absolute;
	    bottom: 2rem;
	    font-size: 2rem;
	    transition: all .3s ease-in-out;
	    display: flex;
	    align-items: center;
	    padding: 1rem;
	    color: #fff;
	    font-size: 1.6rem;
	    z-index: 99999;
	    cursor: pointer;

	    @include media('>=tablet-sm') {
			bottom: 5rem;
	    }
	}

	&__prev,
	&__next {
		display: block;
		height: 5rem;
		width: 5rem;
		font-size: 0;
		border: .1rem solid transparent;
		transition: all .3s ease-in-out;
		border-radius: 5rem;
		background-repeat: no-repeat;
		background-size: 1.5rem auto;

		&:hover {
			border-color: rgba(#fff, .5);
		}
	}


	&__prev {
		left: 2rem;
		margin-right: 1rem;
		background-image: url(../images/icn_arrow_left_white.svg);
		background-position: 45% center;

		@include media('>=tablet-sm') {
			left: 5rem;
		}
	}

	&__next { 
		right: 2rem;
		margin-left: 1rem;
		background-image: url(../images/icn_arrow_right_white.svg);
		background-position: 55% center;

		@include media('>=tablet-sm') {
			right: 5rem;
		}
	}

	.m-heading {
		margin-bottom: 1rem;

		.m-heading__title {
			color: $c-blue !important;
		}
	}
}

.m-list {
	ul {
		position: relative;
		margin-top: 2rem;
	}
	.m-heading {
		margin-bottom: 3rem;
	}

	&__item {
		margin-bottom: 2rem;
		position: relative;

		&__title {
			font-weight: 700;
			font-size: 2.1rem;
			margin-bottom: .5rem;
			color: $c-green;

			@include media('>=tablet-sm') {
				font-size: 3rem;
			}
		}

		&__content {
			line-height: 1.5;
		}

		a {
			color: $c-blue;

			.m-list__item__title {
				color: $c-blue;
			}
		}
	}

	&--nums {
		margin: 0 auto;

		ol {
			counter-reset: ol-list-counter;
			list-style: none;
			margin: 2rem 0;
			
			li {
				counter-increment: ol-list-counter;
				position: relative;
				color: $c-blue;
				padding: 0 0 0 4rem;
			    border-radius: 2.7rem;

			    @include media('<tablet-sm') {
					padding: 0 0 0 3.4rem;
			    }

			    @include media('>=laptop') {
			    	padding-left: 0;
			    }

				&:not(:last-child) {
					margin-bottom: 4rem;
				}

				&::before {
					content: counter(ol-list-counter);
					color: $c-green;
					font-size: 2rem;
					font-weight: 700;
					font-family: $font-h;
					position: absolute;
					left: 0;
					width: 3rem;
					height: 3rem;
					top: .2rem;
					border: .2rem solid $c-gray-md;
					border-radius: 50%;
					text-align: center;
					line-height: 1.3;
					padding: 0;

					@include media('<tablet-sm') {
						font-size: 1.6rem;
						width: 2.4rem;
						height: 2.4rem;
						top: 0;
					}

					@include media('>=laptop') {
						left: -5rem;
					}
				}
			}
		}
	}

	&--lines {

		ul {
			margin: 0 0 0 -2rem;
		}

		h6 {
			font-size: 2.6rem;
		}

		.m-list__item {
			margin-bottom: 1rem;

			a {
				display: block;
				padding: 2rem;
				transition: all .3s ease-in-out;
				&:hover {
					background: $c-green;
				}
			}

			&:not(:last-of-type) {
				&::after {
					margin: 0;
				    width: 100%;
					height: .2rem;
					background: $c-green;
					content: '';
					display: block;
				}
			}
		}

		.m-heading {
			margin-bottom: 1.5rem;
		}
	}

	&--dots {
		padding-left: 4.5rem;

		.m-list__item {
			margin-bottom: 3rem;

			&::before,
			&::after {
				border-radius: 10rem;
				width: 2rem;
				height: 2rem;
				display: block;
				content: '';
				position: absolute;
				left: -4rem;
				top: 0;
			}

			&::before {
				background: $c-green;
			}

			&::after {
				width: 3rem;
				height: 3rem;
				border: .1rem solid $c-green;
				left: -4.5rem;
				top: -.5rem;
				transition: all .3s ease-in-out;
			}

			&:hover {
				&::before {
					background: $c-blue;
					transform: scale(1.2);
				}
				&::after {
					transform: scale(1.4);
					border-color: $c-blue;
				}
			}
		}
	}
}

.m-icon-boxes {
	grid-column-gap: 5rem;
	grid-row-gap: 3rem;
	padding: 2rem 0;

	@include media('<tablet-sm') {
		padding: 4rem 0 2rem;
		grid-row-gap: 5rem;
	}

	@include media('<tablet-lg') {
		grid-column-gap: 2.5rem;
	}

	&--white {
		.m-icon-box {
			color: #fff;

			h4 {
				color: #fff;
			}
		}
	}
}

.m-icon-box {
	position: relative;

	&__icon {
		margin-bottom: 1rem;
		max-height: 5rem;
    	max-width: 5rem;
	}

	h4 {
		margin-bottom: 1.5rem;
		font-weight: 900;
	}

	&__header {
		padding: 5rem 3.5rem 1rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		text-align: center;
	}

	&__content {
		padding: 1.5rem 3.5rem 3rem;
		text-align: center;

		p {
			line-height: 1.5;
		}
	}
}

.m-button-row {
	flex-direction: row !important;
	flex-wrap: wrap;
	padding-top: 1rem;
	// @include media('<tablet-sm') {
	// 	justify-content: center;
	// }

}

// .m-preloader {
// 	//background: linear-gradient(135deg, $c-blue, $c-blue) !important;
// }

.single-event {
	.m-content-form {
		// &__hero {
		// 	//min-height: 40rem;
		// }
		&__form {
			padding: 5rem 0 7rem;

			@include media('>=tablet-sm', '<tablet-lg') {
				padding-left: 2rem;
			}

			@include media('>=tablet-lg') {
				padding: 5rem 0 7rem 10%;
			}
		}
	}
}

.m-events {
	.m-button {
		display: inline-block;
	}

	.l-grid {
	    grid-column-gap: 4rem;
	    grid-row-gap: 4rem;

	    @include media('<tablet-sm') {
			grid-row-gap: 2.5rem;
	    }
	}
}
.m-tabs {
	display: block;
	width: 100%;
	margin: 2rem auto;

	nav {
		display: flex;
		justify-content: center;
		align-items: stretch;

		@include media('<tablet-lg') {
			flex-wrap: wrap;
		}

		button {
			display: block;
			flex: 1 1 0px;
			cursor: pointer;
			padding: 2rem 2.5rem;
			font-size: 1.6rem;
			color: #fff;
			background-color: $c-blue;
			appearance: none;
			outline: none;
			border: 0;
			font-family: $font-b;
			font-weight: 600;
			transition: background-color 250ms ease-in-out;

			@include media('<tablet-lg') {
				width: 100%;
				flex: initial;
			}

			&:not(:last-of-type) {
				border-right: 1px solid lighten($c-blue, 8%);

				@include media('<tablet-lg') {
					border-right: 0;
					border-bottom: 1px solid lighten($c-blue, 8%);
				}
			}

			&.active {
				background-color: #174082;
			}

			&:hover {
				background-color: #174082;
			}
		}
	}
}
.m-tab {
	background-color: #fff;
	border-bottom-left-radius: 2rem;
	border-bottom-right-radius: 2rem;
	opacity: 0;
	pointer-events: none;
	z-index: -1;
	max-height: 0;
	overflow: hidden;

	&.active {
		opacity: 1;
		pointer-events: all;
		z-index: 1;
		max-height: none;
		padding: 2rem 3rem;
	}
}
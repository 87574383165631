.u-hide {
	display: none !important;
}

.u-above {
	z-index: 20;
}

.u-col--b-r {
	border-right: .1rem solid darken($c-green, 5%);
	padding-right: 5%;
}

.u-p-l { 
	&--lg {
		padding-left: 2rem !important;

		@include media('>=tablet-sm') {
			padding-left: 10% !important;
		}
	}
}

.u-p-r { 
	&--lg {
		padding-right: 2rem !important;

		@include media('>=tablet-sm') {
			padding-right: 10% !important;
		}
	}
}

.u-p-t { 
	&--none {
		padding-top: 0 !important;
	}
	&--sm {
		padding-top: 2.5rem !important;

		@include media('>=tablet-sm') {
			padding-top: 3.7rem !important;
		}

		@include media('>=tablet-lg') {
			padding-top: 5rem !important;
		}
	}
	&--md {
		padding-top: 5rem !important;

		@include media('>=tablet-sm') {
			padding-top: 7.5rem !important;
		}

		@include media('>=tablet-lg') {
			padding-top: 10rem !important;
		}
	}
	&--lg {
		padding-top: 12rem !important;

		@include media('>=tablet-sm') {
			padding-top: 14rem !important;
		}

		@include media('>=tablet-lg') {
			padding-top: 16rem !important;
		}
	}
	&--xl {
		padding-top: 18rem !important;

		@include media('>=tablet-sm') {
			padding-top: 20rem !important;
		}

		@include media('>=tablet-lg') {
			padding-top: 22rem !important;
		}
	}
}

.u-p-b {
	&--none {
		padding-bottom: 0 !important;
	}
	&--sm {
		padding-bottom: 2.5rem !important;

		@include media('>=tablet-sm') {
			padding-bottom: 3.7rem !important;
		}

		@include media('>=tablet-lg') {
			padding-bottom: 5rem !important;
		}
	}
	&--md {
		padding-bottom: 5rem !important;

		@include media('>=tablet-sm') {
			padding-bottom: 7.5rem !important;
		}

		@include media('>=tablet-lg') {
			padding-bottom: 10rem !important;
		}
	}
	&--lg {
		padding-bottom: 12rem !important;

		@include media('>=tablet-sm') {
			padding-bottom: 14rem !important;
		}

		@include media('>=tablet-lg') {
			padding-bottom: 16rem !important;
		}
	}
	&--xl {
		padding-bottom: 18rem !important;

		@include media('>=tablet-sm') {
			padding-bottom: 20rem !important;
		}

		@include media('>=tablet-lg') {
			padding-bottom: 22rem !important;
		}
	}
}

.u-o-t {
	&--sm {
		@include media('>=tablet-sm') {
			margin-top: -4rem;
		}
	}
	&--md {
		margin-top: -4rem;

		@include media('>=tablet-sm') {
			margin-top: -8rem;
		}
	}
	&--lg {
		margin-top: -6rem;

		@include media('>=tablet-sm') {
			margin-top: -12rem;
		}
	}
	&--xl {
		margin-top: -9rem;

		@include media('>=tablet-sm') {
			margin-top: -16rem;
		}
	}
	&--xxl {
		margin-top: -12rem;

		@include media('>=tablet-sm') {
			margin-top: -24rem;
		}
	}
}

.u-o-b {
	&--sm {
		@include media('>=tablet-sm') {
			margin-bottom: -4rem;
		}
	}
	&--md {
		margin-bottom: -2rem;

		@include media('>=tablet-sm') {
			margin-bottom: -8rem;
		}
	}
	&--lg {
		margin-bottom: -4rem;

		@include media('>=tablet-sm') {
			margin-bottom: -12rem;
		}
	}
	&--xl {
		margin-bottom: -9rem;

		@include media('>=tablet-sm') {
			margin-bottom: -16rem;
		}
	}
}

.u-warning {
	color: $c-func-error;
}

.u-white {
	color: #fff;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color: #fff;
	}

	a {
		&:not(.m-button) {
			color: $c-green;
		}
	}
}

.u-green {
	color: $c-green;
}

.u-blue {
	color: $c-blue;
}

.u-bg--gray-l {
	background-color: $c-gray-l;
}

.u-bg--green {
	background-color: $c-green;
}

.u-bg--blue {
	background-color: $c-blue;
}

.u-bg--brown {
	background-color: $c-brown;
}

.u-bg--white {
	background-color: #fff;
}

.u-bg--tree-blue {
	background: url(../images/bg_tree_blue.jpg) center/cover no-repeat;
}

.u-bg--tree-brown {
	background: url(../images/bg_tree_brown.jpg) center/cover no-repeat;
}

.u-bg--tree-green {
	background: url(../images/bg_tree_green_2.jpg) center/cover no-repeat;
}

.u-100 {
	width: 100%;
}

.u-mobile {
	@include media('>=tablet-nav') {
		display: none !important;
	}
}

.u-leaf {
	@include media('>=tablet-lg') {
		&::before {
		    width: 100%;
		    height: 100%;
		    top: 0;
		    left: 0;
		    position: absolute;
		    content: '';
		    display: block;
		    background-size: cover;
		    background-repeat: no-repeat;
		    background-position: center;
		    z-index: 2;
		}

		&--blue {
			&::before {
				width: 36vw;
				top: 0;
				bottom: 0;
				left: calc(50% - 18vw);
				background-image: url(../images/leaf_outline_blue.svg);
			}
		}

		&--white-bottom-right {
			&::before {
				width: 23rem;
				height: 46rem;
				top: auto;
				bottom: 0;
				left: auto;
				right: 0;
				background-image: url(../images/leaf_outline_white_right.svg);
			}
		}
	}
}

.u-bg-img--top {
	background-position: center top;
}

.u-bg-img {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	@include media('<tablet-sm') {
		background-size: 165% auto;
	    background-position: right top;
	    padding-top: 56vw !important;
	}

	@include media('<tablet-lg') {
		background-size: 237vw auto;
	    background-position: 67% top;
	    padding-top: 0;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vw;
		background: linear-gradient(0deg, #fff, #fff 30%, rgba(#fff, 0) 60%, rgba(#fff, 0)) !important;

		@include media('<tablet-lg') {
			height: 100vw;
		}

		@include media('>=tablet-lg') {
			display: none;
		}
	}
}

.u-overlay {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;

	&::before {
		display: block;
		position: absolute;	
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 1;
		content: '';
	}

	&::before {
		background: linear-gradient(10deg, rgba($c-brown, .8) 10%, rgba($c-brown, 0));
		mix-blend-mode: multiply;

		@include media('<tablet-sm') {
			background: rgba(#312A24, .64);
		}
	}

	// &--dark {
	// 	&::before {
	// 		background: linear-gradient(90deg, $c-green, $c-blue 30%, lighten($c-blue, 5%) 65%, $c-green) !important;
	// 	}
	// }

	&--d {
		&::before {
			background: linear-gradient(0deg, rgba(#312A24, .84), rgba(#312A24, .6));

			@include media('<tablet-sm') {
				background: rgba(#312A24, .84);
			}
		}
	}

	&--blue {
		&::before {
			opacity: .5;
			background: $c-blue !important;
		}
	}
}

.u-object-fit {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 0;
	bottom: 0;
	overflow: hidden;

	defer-image {
		height: 100%;

		picture {
			img {
				object-fit: cover;
				max-width: none;
				object-position: center;
			    height: 100%;
			    width: 100%;
			}
		}
	}

	& ~ * {
		position: relative;
		z-index: 3;
	}
}

.u-content-below {
	.l-wrapper {
		z-index: 2;
	}
}

.u-notice {
	border-radius: .5rem;
	padding: 1.4rem 2rem;
    line-height: 1.4;
    color: $c-func-success;
}

.u-p-col--0 {
	.l-col:not(:last-child) {
		@include media('<tablet-sm') {
			margin-bottom: .2rem;
		}
	}
}

.u-reverse {
	.l-row {
		@include media('<tablet-sm') {
			flex-direction: column-reverse;
		}

		.l-col {
			@include media('<tablet-sm') {
				margin-bottom: 0;
			}

			&:not(:first-child) {
				@include media('<tablet-sm') {
					margin-bottom: 2rem;
				}
			}
		}
	}
}

.u-col-full-lg {
	.l-col {
		@include media('<tablet-lg') {
			width: 100% !important;
		}
	}
}

.u-split-col {
	.l-col {
		@include media('>=tablet-sm') {
			width: calc(50% - .2rem) !important;
		}
	}
}
.l-grid {
	$root: &;
	display: grid;
	grid-column-gap: 2rem;
	grid-row-gap: 4rem;
	width: 100%;

	@include media('>=tablet-sm') {
		grid-column-gap: 3rem;
    	grid-row-gap: 4rem;
    }

	@include media('>=tablet-lg') {
		grid-column-gap: 5rem;
		grid-row-gap: 6rem;
	}

	&--narrow {
		width: 75%;
		margin-left: auto;
		margin-right: auto;
	}

	&--2 {
		@include media('>=phone-lg') {
			grid-template-columns: 1fr 1fr;
			@include msgrid(12, 2, 2rem, 2rem);
		}
	}
	&--3 {
		// @include media('>=phone-lg') {
		// 	grid-template-columns: 1fr 1fr;
		// 	@include msgrid(12, 2, 2rem, 2rem);
		// }
		@include media('>=phone-lg') {
			grid-template-columns: 1fr 1fr 1fr;
			@include msgrid(12, 3, 2rem, 2rem);
		}
	}
	&--4 {
		@include media('>=phone-md') {
			grid-template-columns: 1fr 1fr;
			@include msgrid(12, 2, 2rem, 2rem);
		}

		@include media('>=tablet-md') {
			grid-template-columns: 1fr 1fr 1fr 1fr;
			@include msgrid(12, 4, 2rem, 2rem);
		}
	}
	&--5 {
		@include media('>=phone-lg') {
			grid-template-columns: 1fr 1fr;
			@include msgrid(12, 2, 2rem, 2rem);
		}

		@include media('>=tablet-lg') {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			@include msgrid(12, 5, 2rem, 2rem);
		}
	}
	&--6 {
		@include media('>=phone-md') {
			grid-template-columns: 1fr 1fr;
			@include msgrid(12, 2, 2rem, 2rem);
		}

		@include media('>=tablet-sm') {
			grid-template-columns: 1fr 1fr 1fr;
			@include msgrid(12, 3, 2rem, 2rem);
		}

		@include media('>=tablet-md') {
			grid-template-columns: 1fr 1fr 1fr 1fr;
			@include msgrid(12, 4, 2rem, 2rem);
		}

		@include media('>=tablet-lg') {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
			@include msgrid(12, 6, 2rem, 2rem);
		}
	}

	&--gap-md {
		grid-column-gap: 4rem;

		@include media('<tablet-sm') {
			grid-row-gap : 7rem;
		}

		&#{$root}--2 {
			@include msgrid(12, 2, 4rem, 4rem);
		}

		&#{$root}--3 {
			@include msgrid(12, 3, 4rem, 4rem);
		}

		&#{$root}--4 {
			@include msgrid(12, 4, 4rem, 4rem);
		}
	}
}
/** Mixins */
@mixin bg {
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% auto;
}
@mixin bg-cover {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
@mixin word-wrap {
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	hyphens: auto;
}

/**
 * MS Grid
 */
@mixin msgrid($totalItems, $totalColumns, $rowGap:"", $colGap:"") {
	$i:			1;
	$row:		1;
	$column:	1;

	$colOneLess: $totalColumns - 1;
	$colMax: ($totalColumns * 2) - 1;
	@if $colGap != "" {
		@if $totalColumns == 1 {
			-ms-grid-columns: 1fr;
		}@else {
			-ms-grid-columns: unquote("(1fr #{$colGap})[#{$colOneLess}] 1fr");
		}
	}

	@while $i <= $totalItems {
		*:nth-child(#{$i}) {
			-ms-grid-row: #{$row}; //Row Declaration

    		@if $i % $totalColumns == 0 { //On last item in row
				-ms-grid-column: $column;
				$row: $row + 1; //Set next row
				$column: 1; //Reset column count
			}@else {
				-ms-grid-column: $column;
				@if $colGap != "" { //Col Gap fix
					$column: $column + 2; //Increment column
				}@else {
					$column: $column + 1; //Increment column
				}
			}
			@if $rowGap != "" {
				@media all and (-ms-high-contrast:none) { //Row Gap fix specifically IE11+
					margin-bottom: #{$rowGap};
				}
			}
		}
		$i: $i + 1;
	}
}

@mixin button {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.7rem;
	font-weight: 400;
	padding: 0;
	border-radius: 0;
	transition: all 250ms ease-in-out;
	position: relative;
	cursor: pointer;
	border: .2rem solid #fff;
	border-radius: 2.7rem;
	line-height: 1.2;
	overflow: hidden;
	font-family: $font-b;
	padding: 1.9rem 2.5rem 2.1rem 2rem;
	color: #fff;
	margin-bottom: 2.5rem;
	outline: 0;
	background: url(../images/bg-tree-green.jpg) center/cover $c-green no-repeat;

	@include media('<tablet-sm') {
		margin-left: .7rem;
		margin-right: .7rem;
	}

	b {
		margin-left: .5rem;
	}

	&:hover {
		color: #fff;

		.m-button__text {
			&::after {
		    	transform: translate(.5rem, 0);
			}
		}
	}

	&:focus,
	&:active {
		color: #fff;
	}

	&--lg {
		font-size: 1.8rem;
		padding: 2.5rem 4rem;

		@include media('>=tablet-sm') {
			//font-size: 1.8rem;
		}

		.m-button__inner-bg { 
			svg {
				height: 6.8rem;

				&:nth-of-type(1),
				&:nth-of-type(3) {
					width: 3.8rem;
				}

				&:nth-of-type(2) {
					width: calc(100% - 7.6rem);
				}
			}
		}
	}

	&:not(:last-of-type) {
		@include media('>=tablet-sm') {
			margin-right: 1.5rem;
		}
	}

	&__inner-bg {
		display: block;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: absolute;
		left: 0; 
		right: 0;
		bottom: 0;
		top: 0;
		z-index: 0;
		display: flex;

		&::after {
			opacity: 0;
			background: darken($c-blue, 10%);
			position: absolute;
			z-index: -1;
			top: .5rem;
			left: .5rem;
			bottom: .5rem;
			right: .5rem;
			display: block;
			content: '';
			border-radius: 3rem;
			transition: all .3s ease-in-out;
		}
	}

	.m-button__text {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 1rem;
		position: relative;
		z-index: 2;

		&::after {
			content: '';
			background: url(../images/icn_arrow_right_white.svg) no-repeat;
			background-size: contain;
			display: block;
			height: .8rem;
		    width: .7rem;
		    margin-left: .5rem;
		    transition: all .3s ease-in-out;
		    top: .2rem;
    		position: relative;
		}
	}
}



@mixin form-styles {
	label {
		cursor: pointer;
		display: block;
		width: 100%;
		font-weight: 700;
		font-size: 1.4rem;
		margin-bottom: .9rem;
		color: $c-blue;
		letter-spacing: .1rem;
		padding: 0 0 0 1.5rem;
	}

	input[type=radio],
	input[type=checkbox] {
		display: none;

		& + label {
			display: block;
			width: 100%;
			padding-left: 2.5rem;
			position: relative;
			line-height: 1;
			margin-bottom: 1rem;
			font-size: 1.6rem;

			* {
				line-height: 1;
			}

			&::before,
			&::after {
				content: '';
				display: block;
				border-radius: 100%;
				position: absolute;
				transition: all 250ms ease-in-out;
			}
			&::before {
				width: 1.5rem;
				height: 1.5rem;
				border: 1px solid $c-blue;
				background-color: #fff;
				z-index: 1;
				top: 0;
				left: 0;
			}
			&::after {
				width: 0.7rem;
				height: 0.7rem;
				background-color: $c-blue;
				top: 4px;
				left: 4px;
				z-index: 3;
				opacity: 0;
				transform: scale(0.8);
			}

			&:focus::before {
				border-color: $c-blue;
			}
		}

		&:checked + label {
			font-weight: 700;

			&::before {
				border-color: $c-blue;
			}
			&::after {
				opacity: 1;
				transform: scale(1);
			}
		}
	}
	input[type=checkbox] {
		& + label {
			&::before,
			&::after {
				border-radius: 0;
			}
		}
	}
	
	input:not([type=submit]):not([type=radio]):not([type=checkbox]),
	textarea,
	select {
		background: #fff;
		cursor: pointer;
		transition: all .3s ease-in-out;
		font-weight: 400;
		font-family: $font-b;
		font-size: 1.4rem;
		color: $c-blue;
		resize: vertical;
		width: 100%;
		padding: 1.5rem 2rem;
		border: 0;
		border-radius: 2rem;

		&::-webkit-input-placeholder { /* WebKit browsers */
			color: $placeholder-color;
		}
		&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
			color: $placeholder-color;
		}
		&::-moz-placeholder { /* Mozilla Firefox 19+ */
			color: $placeholder-color;
		}
		&:-ms-input-placeholder { /* Internet Explorer 10+ */
			color: $placeholder-color;
		}

		&:focus {
			background-color: #fff;
		}

		&.invalid {
			border-color: $c-func-warning;
		}

		&:focus {
			border-color: $c-blue;
		}
	}

	.ginput_container_password > span {
		position: relative;

		button {
			border: 0;
			appearance: none;
			background: transparent;
			cursor: pointer;
			padding: 0;
			position: absolute;
			right: 1rem;
			top: 1.2rem;
			z-index: 2;
		}
	}
	
	input[type=submit] {
		@include button; 
		background-size: cover !important;
	}

	textarea {
		height: 10rem;
	}

	select {
		height: 4.6rem;
		background-image: url('../images/icn_arrow_down_gray.svg');
		background-repeat: no-repeat;
		background-size: 1.3rem auto;
		background-position: calc(100% - 2rem) 50%;
	}

	option {
		font-weight: 700;
	}

	option[value=""] {
	  	font-weight: 300;
	}

	& > p {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	.no-main-label {
		& > label {
			display: none !important;
		}
	}

	.gform_fields {
		display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	}

	.gfield {
		margin-bottom: 3rem;
		width: 100%;

		&.one-third {
			@include media('>=tablet-md') {
				width: 30%;
			}
		}

		&.gform_hidden {
			margin: 0;
			padding: 0;
			height: 0;
		}
	}

	.ginput_complex {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;

		& > * {
			width: 48%;
		}

		label {
			font-size: 1.2rem;
			display: block;
			margin-top: 1rem;
			color: $c-green;
			text-transform: uppercase;
			font-weight: 700;
			padding-left: 1rem;
		}
	}

	.field_sublabel_above .ginput_complex label {
		display: block;
		width: 100%;
		font-weight: 700;
		font-size: 1.4rem;
		margin-top: 0;
		margin-bottom: 1rem;
		text-transform: none;
		padding-left: 0;
		color: $c-blue;
	}

	.gform_footer {
		padding: 0 !important;
		margin: 0 auto;
		position: relative;
		display: flex;
		justify-content: flex-end;

		button {
			@include button; 

			width: 100%;
			font-size: 2rem;
			font-weight: 900;
			border-radius: 2.8rem;
			background: url(../images/bg-tree-green.jpg) no-repeat;
			background-size: cover;
			position: relative;

			&::after {
				content: '';
				background: url(../images/icn_arrow_right_white.svg) no-repeat;
				background-size: contain;
				display: block;
				height: 1.2rem;
				width: .9rem;
			    margin-left: 1rem;
			    transition: all .3s ease-in-out;
			    transition: all .3s ease-in-out;
			}

			&:hover {
				&::after {
					transform: translate(.5rem, 0);
				}
			}
		}
	}

	.validation_error {
		display: block;
		color: #fff;
		background-color: $c-func-warning;
		padding: 1rem;
		margin-bottom: 2rem;
		font-size: 1.4rem;
		font-weight: 400;
		border-radius: .3rem;
	}

	.gfield_description.validation_message {
		display: block;
		color: #fff;
		padding: 1rem;
		margin-top: 1rem;
		margin-bottom: 3rem;
		font-size: 1.4rem;
		font-weight: 400;
		border-radius: .3rem;
		background: $c-func-warning;
	}

	.gform_confirmation_wrapper {
		padding: 3rem;
    	border-radius: 1rem;
    	background: #fff;
    	color: $c-blue !important;

		@include media('<tablet-sm') {
			text-align: center;
		}	
	}

	@keyframes spinner {
		0% {
			transform: rotate(0deg);
		}
		50% {
			transform: rotate(180deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	.gform_ajax_spinner {
		display: inline-block;
		border: 4px solid rgba(0, 0, 0, 0.2);
		border-left-color: #fff;
		border-radius: 50%;
		width: 3rem;
		height: 3rem;
		animation: spinner 1.2s linear infinite;
		z-index:30;
		position: absolute;
		right: -5rem;
		top: 1.2rem;
	}
}